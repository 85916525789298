import React from 'react';
import htmlToImage from 'html-to-image';

class ExportPNG extends React.Component{

    state = { url: 5 }

    render(){
        let _this = this;
        if(this.props.svg){
            const svg = this.props.data_key !== undefined ? document.querySelector(`.item_${this.props.data_key} svg`) : document.querySelector('svg');
            htmlToImage.toPng(svg)
                .then(function (dataUrl) {
                    if(_this.state.url !== dataUrl && dataUrl !== 'data:,') _this.setState({ url: dataUrl });
                });
            return (
                <div>
                    <br />
                    <a className="saveSVG"  href={ this.state.url } download={ this.props.name+'.png' } > Скачать как png </a> 
                </div> 
            );
        } else {
            return ( <div> </div> );
        }
    }
}

export default ExportPNG;