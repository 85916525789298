import React from 'react';
import JSZip from 'jszip';

import Genery from '../genery/Genery.js';
import ExportSVG from '../components/ExportSVG.js';
import ExportPNG from '../components/ExportPNG.js';



class EnterStr extends React.Component{
    state = {
        str: '',
        list: [], 
        color: '#000',
        logo: true,
        layer: 'Q',
        wrap: 'td_custom_qr',
        zip: 0,
        preloader: 0
    }

    handleChange = e => {
        this.setState({ str: e.target.value });
    }

    handleLogo = () => {
        this.setState({ logo: !this.state.logo }, 
            this.setState({ layer: this.state.logo ? 'L' : 'Q' }));
    }

    handleColor = e => {
        this.setState({ color: e.target.value });
    }

    updateList = () => {
        const str = this.state.str === document.querySelector(`.url_wrap textarea`).value ? '' : document.querySelector(`.url_wrap textarea`).value;
        if(str !== ''){
            const strings = this.delBr(str);
            let list = [];
            strings.map( item => list.push({ str: item, svg: '' }) );
            if(this.zip) document.getElementById('save_all').href = '';
            this.setState({ list, zip: 0 });
        }
    }

    delBr = str => {
        return str.replace(/(^\s*)|(\s*)$/g, '').replace(/[\n\r]/g, ' ').replace (/\s{2,}/g, ' ').split(" ")
    }

    updateSVG = (svg, key) => {
        let list = this.state.list;
        list.map(item => { 
            if(item.str === key && item.svg !== svg){
                item.svg = svg 
                this.setState({ list });
            }
        });
    }

    saveZIP = (e, arg) => {
        if(arg) this.setState({ preloader: 1 })
        const svg       = document.querySelectorAll('.qr_wrapper'),
        title     = document.querySelectorAll('.title');
        let   url_wrap  = e.target;
        console.log('name')
        let zip = new JSZip();
        svg.forEach( (e, key) => { 
            const name = title[key].innerHTML.replace(new RegExp("/",'g'),"_")
            console.log(name)
            zip.file(name+".svg", e.innerHTML)
        });
        zip.generateAsync({type:"base64"}).then( base64 => { 
            url_wrap.href =  "data:application/zip;base64," + base64;
            url_wrap.download = "all-qr-codes.zip"
            this.setState({ 
                zip : 1,
                preloader: 0
             })
        })
    }

    render(){
        const { str, list, preloader, zip, logo, color } = this.state;
        return (
            <div className="group">
                <div className="url_wrap">
                    <textarea type="text" defaultValue={ str } 
                    placeholder="https://revizion.ua 
https://revizion.ru 
https://revisend.com"
                    >
                    </textarea>
                    <span className="qr_active_logo" onClick={ this.updateList } >Генерировать</span>
                    <span className="qr_active_logo" onClick={ this.handleLogo } >{ logo ? 'Убрать логотип' : 'Добавить логотип' }</span>
                    <div className="qr_bgcolor_label"> Цвет QR-кода <input type="color" defaultValue={ color } onChange={ this.handleColor } className="qr_bgcolor" /></div>
                    {list.length ?                     
                    <span>
                        { zip ? 
                            <a id="save_all" onClick={ e =>  this.saveZIP(e, false) } className="qr_active_logo">Скачать всё</a> : 
                            <a id="save_all" onClick={ e =>  this.saveZIP(e, true) } className="qr_active_logo">Подготовить архив</a> }
                    </span>
                     : ""}
                    {preloader ? 
                    <div className="letter-holder flex">
                        <div className="l-1 letter">.</div>
                        <div className="l-2 letter">.</div>
                        <div className="l-3 letter">.</div>
                        <div className="l-4 letter">.</div>
                        <div className="l-5 letter">.</div>
                        <div className="l-6 letter">.</div>
                        <div className="l-7 letter">.</div>
                    </div>
                    : ''}
                </div>
                <div className="container flex wrap_group">
                    {list.map((item, key) => (
                        <div className={ "item flex item_"+key }  key={ item }>
                            <div className="td_custom_qr">
                                <Genery string={ item.str } svg={ this.updateSVG } full_str={ str } logo={ this.state.logo } wrap={ this.state.wrap } layer={ this.state.layer } color={ this.state.color } />
                            </div>
                            <div className="td_optins">
                                <span className="title">{ item.str }</span>
                                <ExportSVG svg={ item.svg } str={ this.state.str } name={ item.str }/>
                                <ExportPNG svg={ item.svg } name={ item.str } data_key={ key } />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}

export default EnterStr;
