import React from 'react';
import EnterStr from './EnterStr';
import EnterGroup from './EnterGroup';
import EnterWIFI from './EnterWIFI';

class Tabs extends React.Component {

    state = { tab: 0 }

    changeItem = e => {
        this.setState({  tab: +e.target.getAttribute('data-id') });
    }

    render(){
        const { tab } = this.state;

        const li = this.props.items.map(item => {
            return <li key={ item.id } data-id={ item.id } onClick={ this.changeItem } className={ (item.id === tab) ? 'tabs__list-item active-tab' : 'tabs__list-item' } >{ item.title }</li>
        });

        return(
            <div className="container flex">
                <div className="tabs">
                    <ul className="tabs__list flex"> { li } </ul>
                    <div className="tabs__content"> 
                        {tab === 0 &&  <EnterStr />} 
                        {tab === 1 &&  <EnterWIFI />}  
                        {tab === 2 &&  <EnterGroup />} 
                    </div> 
                </div>
            </div>
        );
    }
}

export default Tabs;
